import { isServer } from '@vue-storefront/core/helpers'
import RootState from '@vue-storefront/core/types/RootState'
import { Logger } from '@vue-storefront/core/lib/logger'
import { Store } from 'vuex'

const EWAY_SCRIPT_URL = 'https://secure.ewaypayments.com/scripts/eWAY.min.js'
const EWAY_SCRIPT_SANDBOX_URL = 'https://secure.ewaypayments.com/scripts/eWAY.js'
const EWAY_SDK_URL = 'https://static.assets.eway.io/cerberus/6.6.2.54470/assets/sdk/cerberus.bundle.js'
const EWAY_SDK_SANDBOX_URL = 'https://static.assets.eway.io/cerberus/6.6.2.54470/assets/sdk/cerberus-sandbox.bundle.js'

export function beforeRegistration (config: any, store: Store<RootState>) {
  if (!isServer && config.eWay && !window['eWAY']) {
    const env = config.eWay.env || 'sandbox'

    Logger.debug('Loading eWay script for ' + env, 'eWAY')()

    let url = EWAY_SCRIPT_URL

    if (env === 'sandbox') {
      url = EWAY_SCRIPT_SANDBOX_URL
    }

    // load script for eWay
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      url
    )
    plugin.async = true
    document.head.appendChild(plugin)

    // load script for eWay 3ds SDK
    let sdkUrl = EWAY_SDK_URL

    if (env === 'sandbox') {
      sdkUrl = EWAY_SDK_SANDBOX_URL
    }

    const sdk = document.createElement('script')
    sdk.setAttribute('src', sdkUrl)
    sdk.async = true
    document.head.appendChild(sdk)

    const style = document.createElement('style')
    style.innerHTML = 'body .cerberus-sandbox-modal, .body .cerberus-modal { color: #000; z-index: 9999; }'
    document.head.appendChild(style)
  }
}

import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import rootStore from '@vue-storefront/core/store'
import EWayState from './state'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import i18n from '@vue-storefront/i18n'
import { Logger } from '@vue-storefront/core/lib/logger'

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
}

const actions: ActionTree<EWayState, RootState> = {
  async loadCustomerSavedCards (context) {
    let customerToken = rootStore.getters['user/getToken']
    const url: string = processLocalizedURLAddress(config.eWay.endpoint.loadUserSavedCards)
      .replace('{{customerToken}}', customerToken)

    const response = await TaskQueue.execute({
      url: url,
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    })
    let cards = []
    if (response.code === 200 && response.result) {
      if (response.result) {
        JSON.parse(response.result, (key, value) => {
          return typeof value === 'string' ? cards.push(JSON.parse(value)) : ''
        })
      }

      const defaultCard = cards.find(card => card.DefaultToken ? card.DefaultToken : null)

      if (defaultCard) {
        context.commit(types.SET_USER_DEFAULT_CARD_ID, defaultCard.Id)
      } else {
        context.commit(types.SET_USER_DEFAULT_CARD_ID, cards[0].Id)
      }

      context.commit(types.SET_USER_SAVED_CARDS, cards)
    } else {
      context.commit(types.SET_USER_SAVED_CARDS, null)
    }
  },
  async saveCreditCard (context, data) {
    EventBus.$emit('notification-progress-start')

    let customerToken = rootStore.getters['user/getToken']
    const url: string = processLocalizedURLAddress(config.eWay.endpoint.saveCard)

    const response = await TaskQueue.execute({
      url: url,
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({
          data: JSON.stringify(data),
          token: customerToken
        })
      }
    })
    EventBus.$emit('notification-progress-stop')
    EventBus.$emit('modal-hide', 'modal-cardPopUp')

    if (response.code === 200 && response.result) {
      rootStore.dispatch('user/me')
      rootStore.dispatch('payment-eway/loadCustomerSavedCards')
      rootStore.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t(response.result),
        action1: { label: i18n.t('OK') }
      })
      return response.result
    } else {
      Logger.error('Something went wrong. Try again in a few seconds.', 'eway-cards')()
    }
  },
  async deleteCreditCard (context, cardId) {
    EventBus.$emit('notification-progress-start')

    let customerToken = rootStore.getters['user/getToken']
    const url: string = processLocalizedURLAddress(config.eWay.endpoint.deleteCard)

    const response = await TaskQueue.execute({
      url: url,
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({
          id: cardId,
          token: customerToken
        })
      }
    })

    EventBus.$emit('notification-progress-stop')

    if (response.code === 200 && response.result) {
      rootStore.dispatch('user/me')
      rootStore.dispatch('payment-eway/loadCustomerSavedCards')
      rootStore.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t(response.result),
        action1: { label: i18n.t('OK') }
      })
    } else {
      Logger.error('Something went wrong. Try again in a few seconds.', 'eway-cards')()
    }
  },
  async setDefaultCard (context, cardId) {
    EventBus.$emit('notification-progress-start')
    let customerToken = rootStore.getters['user/getToken']
    const url: string = processLocalizedURLAddress(config.eWay.endpoint.setDefault)

    const response = await TaskQueue.execute({
      url: url,
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({
          id: cardId,
          token: customerToken
        })
      }
    })

    EventBus.$emit('notification-progress-stop')
    if (response.code === 200 && response.result) {
      rootStore.dispatch('user/me')
      context.commit(types.SET_USER_DEFAULT_CARD_ID, cardId)

      rootStore.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t(response.result),
        action1: { label: i18n.t('OK') }
      })
      return response.result
    } else {
      Logger.error('Something went wrong. Try again in a few seconds.', 'eway-cards')()
    }
  },
  async create3dsEnrolment ({ commit }, payload) {
    EventBus.$emit('notification-progress-start')
    const quoteId = rootStore.getters['cart/getCartToken']

    const response = await TaskQueue.execute({
      url: processLocalizedURLAddress(config.eWay.endpoint.create3dsEnrolment),
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          quoteId: quoteId,
          securedCardData: payload.SecuredCardData,
          tokenID: payload.TokenID || ''
        })
      }
    })

    if (response.code !== 200) {
      const errorMessage = response?.result?.errorMessage || 'Unknown error'
      Logger.error(errorMessage, 'AfterPay')()
      return null
    }

    return response.result
  },
  async verify3dsEnrolment ({ dispatch, commit }, payload) {
    EventBus.$emit('notification-progress-start')
    const quoteId = rootStore.getters['cart/getCartToken']

    const response = await TaskQueue.execute({
      url: processLocalizedURLAddress(config.eWay.endpoint.verify3dsEnrolment),
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          quoteId: quoteId,
          accessCode: payload.AccessCode
        })
      }
    })

    if (response.code !== 200) {
      const errorMessage = response?.result?.errorMessage || 'Unknown error'
      Logger.error(errorMessage, 'AfterPay')()
      return null
    }

    return response.result
  }
}

export default actions
